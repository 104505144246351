import { motion } from 'framer-motion';
import './../../css/main.css';
import { IconType } from '../../api/api_types';
import { Box, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAppSelector } from '../../store/store';
import { useState } from 'react';

interface MainProps {
  mainQuestion: string;
  iconType: IconType;
  id?: string;
  imagePath?: string;
  onRateClick: ({ id, isPositive, rate }: { id?: string, isPositive?: boolean, rate?: number }) => void;
}

export default function Main(props: MainProps) {
  const lang = useAppSelector((state) => state.lang.lang);
  const [rate, setRate] = useState(0);

  function renderRate() {
    if (props.iconType === IconType.FiveStarts) {
      const rateList = Array.from({ length: 5 }, (_, i) => i + 1);

      return <Box>
        <Box display='flex'>
          {rateList.map((r, index) => <IconButton key={index} onClick={() => setRate(index + 1)} sx={{ ml: '6px' }}>{StarSvg(index < rate)}</IconButton>)}
        </Box>
        <LoadingButton
          disabled={rate === 0}
          variant='contained'
          onClick={() => props.onRateClick({ id: props.id, rate: rate })}
          sx={{
            mt: '35px', p: '10px 70px', fontSize: '20px', textTransform: 'none', backgroundColor: '#000', '&:hover': {
              backgroundColor: '#676767'
            }
          }}>
          {lang.continue}
        </LoadingButton>
      </Box>;
    } else {
      const negativeIcon = props.iconType === IconType.Emoji ? '/images/negative.svg' : '/images/dislike.png';
      const positiveIcon = props.iconType === IconType.Emoji ? '/images/positive.svg' : '/images/like.png';

      return <div className='reaction__container'>
        <img
          className='reaction__item'
          src={positiveIcon}
          alt='positive'
          onClick={() => props.onRateClick({ id: props.id, isPositive: true })}
          width={81}
        />
        <img
          className='reaction__item'
          src={negativeIcon}
          alt='negative'
          onClick={() => props.onRateClick({ id: props.id, isPositive: false })}
          width={81}
        />
      </div>;
    }
  }
  return (
    <motion.div
      className='main anim'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {props.imagePath && <img className='logo' src={props.imagePath} />}

      <h2 className='main__title'>{props.mainQuestion}</h2>
      {renderRate()}
    </motion.div>
  );
}

function StarSvg(isFilled: boolean) {
  const color = isFilled ? '#F1B31C' : '#D9D9D9';
  return <svg width="45" height="45" viewBox="0 0 45 45" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0946 7.66887C18.9445 2.5563 20.3695 0 22.5 0C24.6305 0 26.0554 2.55627 28.9053 7.66884L29.6426 8.99153C30.4526 10.4444 30.8574 11.1708 31.4889 11.6501C32.1203 12.1294 32.9064 12.3073 34.4792 12.6631L35.9111 12.9871C41.4452 14.2393 44.2124 14.8654 44.8708 16.9824C45.5291 19.0994 43.6427 21.3055 39.8697 25.7173L38.8937 26.8587C37.8216 28.1124 37.2854 28.7393 37.0442 29.5149C36.8032 30.2904 36.8842 31.1268 37.0462 32.7996L37.1938 34.3225C37.7642 40.2089 38.0495 43.1521 36.326 44.4605C34.6023 45.7689 32.0114 44.5759 26.8296 42.1903L25.4891 41.5729C24.0167 40.8949 23.2805 40.5559 22.5 40.5559C21.7195 40.5559 20.9833 40.8949 19.5109 41.5729L18.1703 42.1903C12.9885 44.5759 10.3976 45.7689 8.67408 44.4605C6.95052 43.1521 7.23572 40.2089 7.80614 34.3225L7.9537 32.7996C8.11581 31.1268 8.19686 30.2904 7.95568 29.5149C7.71452 28.7393 7.17846 28.1124 6.10632 26.8587L5.13022 25.7173C1.35731 21.3055 -0.52915 19.0994 0.129199 16.9824C0.787548 14.8654 3.55468 14.2393 9.08896 12.9871L10.5207 12.6631C12.0934 12.3073 12.8797 12.1294 13.5111 11.6501C14.1425 11.1708 14.5474 10.4444 15.3573 8.99153L16.0946 7.66887Z" fill={color} />
  </svg>

}
