import { useAppDispatch, useAppSelector } from '../../store/store';
import { Snackbar, Alert } from '@mui/material';
import { setFailure } from '../../store/system';

export default function ErrorAlert() {
  const dispatch = useAppDispatch();
  const failure = useAppSelector((state) => state.system.failure);
  const lang = useAppSelector((state) => state.lang.lang);

  return (
    <Snackbar
      open={failure}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={5000}
      onClose={() => {
        dispatch(setFailure(false));
      }}
    >
      <Alert
        severity='error'
        onClose={() => {
          dispatch(setFailure(false));
        }}
      >
        {lang.somethingWentWrong}
      </Alert>
    </Snackbar>
  );
}
