import { Typography, Skeleton, Box } from '@mui/material';
import { AdditionalInfo } from '../../api/api_types';
import Title from './title';

export default function LocationAnalysis(props: { info: AdditionalInfo[] }) {
  return props.info.length === 0 ? (
    <></>
  ) : (
    <>
      <h2
        style={{
          marginTop: '30px',
          marginBottom: '10px',
          fontSize: '16px',
          fontWeight: 500,
          color: '#3780FF',
        }}
      >
        <Title text='Аналіз сторінки локації' />
      </h2>
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: '6px',
          padding: '28px 24px 18px 24px',
          marginTop: '16px',
          border: '1px solid #ccc',
        }}
      >
        {props.info.map((item, index) => {
          return (
            <Box key={index}>
              <Box key={index} display='flex' mb='10px' alignItems='center'>
                <img
                  src={
                    item.checked
                      ? '/images/audit/check.svg'
                      : '/images/audit/cancel.svg'
                  }
                />
                {item.text ? (
                  <Typography fontSize='12px' fontWeight={400} ml='10px'>
                    {item.text}
                  </Typography>
                ) : (
                  <Skeleton
                    key={index}
                    width='100%'
                    animation={false}
                    sx={{ ml: '10px' }}
                  ></Skeleton>
                )}
              </Box>
              {item.hintHtml && (
                <Box
                  m='0 15px 15px 15px'
                  p='15px 24px'
                  sx={{
                    backgroundColor: '#FFF4E1',
                    fontSize: '12px',
                    fontWeight: 400,
                  }}
                  dangerouslySetInnerHTML={{ __html: item.hintHtml }}
                ></Box>
              )}
            </Box>
          );
        })}
      </div>
    </>
  );
}
