import { useEffect } from 'react';
import { MemoryRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, useAppDispatch } from '../store/store';
import App from '../js/App';
import { setGoogleAuth } from '../store/system';
import { AnimatePresence } from 'framer-motion';
import { router } from './router';
import { auditRouter } from './audit_route';

export default function RouterManager() {
  const dispatch = useAppDispatch();

  function isQRCodeUrl() {
    const currentUrl = window.location.href;
    return currentUrl.includes('/qr');
  }

  function isAuditUrl() {
    const currentUrl = window.location.href;
    return currentUrl.includes('/audit/');
  }

  useEffect(() => {
    const images = [
      'images/logo_horizontal.svg',
      'images/negative.svg',
      'images/positive.svg',
      'images/success.svg',
      'images/a_pay.svg',
      'images/g_pay.svg',
      'images/apple_pay.svg',
      'images/google_pay.svg',
      'images/master_card.svg',
      'images/platon.svg',
      'images/visa.svg',
      'images/lock.svg',
      'images/like.png',
      'images/dislike.png',
    ];
    cacheImages(images);

    const checkGoogleAuth = () => {
      const img = new Image();
      img.src =
        'https://accounts.google.com/CheckCookie?continue=https%3A%2F%2Fwww.google.com%2Fintl%2Fen%2Fimages%2Flogos%2Faccounts_logo.png&followup=https%3A%2F%2Fwww.google.com%2Fintl%2Fen%2Fimages%2Flogos%2Faccounts_logo.png&chtml=LoginDoneHtml&checkedDomains=youtube&checkConnection=youtube%3A291%3A1';

      img.onload = () => {
        dispatch(setGoogleAuth(true));
      };

      img.onerror = () => {
        dispatch(setGoogleAuth(false));
      };
    };

    checkGoogleAuth();
  }, [dispatch]);

  const cacheImages = async (images: Array<string>) => {
    const promises = await images.map((image) => {
      return new Promise<void>(function (resolve, reject) {
        const img = new Image();

        img.src = image;
        img.onload = () => resolve();
        img.onerror = () => reject();
      });
    });
    await Promise.all(promises).catch((error) => {});
  };

  return (
    <Provider store={store}>
      {isAuditUrl() ? (
        <RouterProvider router={auditRouter} />
      ) : isQRCodeUrl() ? (
        <AnimatePresence mode='wait'>
          <RouterProvider router={router} />
        </AnimatePresence>
      ) : (
        <MemoryRouter>
          <App />
        </MemoryRouter>
      )}
    </Provider>
  );
}
