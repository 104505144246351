import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useAppSelector } from '../../store/store';

export interface AttributesSelectorProps {
  attributes: string[];
  onClick: (attributes: string[]) => void;
  loading: boolean;
}

export default function AttributesSelector(props: AttributesSelectorProps) {
  const { attributes, loading, onClick } = props;
  const [selectedAttr, setAttr] = useState<string[]>([]);
  const lang = useAppSelector((state) => state.lang.lang);

  const onSelect = (attr: string) => {
    const isSelected = selectedAttr.includes(attr);

    const newAttr = isSelected
      ? selectedAttr.filter((e) => e !== attr)
      : [...selectedAttr, attr];

    setAttr(newAttr);
  };

  function renderAttr() {
    return attributes.map((attr, index) => {
      const isSelected = selectedAttr.includes(attr);

      return (
        <Button
          key={index}
          onClick={() => onSelect(attr)}
          variant={isSelected ? 'contained' : 'outlined'}
          sx={{
            m: '5px',
            borderColor: '#21222E',
            color: isSelected ? '#fff' : '#21222E',
            backgroundColor: isSelected ? '#21222E' : '#fff',
            textTransform: 'none',
            '&:hover': {
              borderColor: '#21222E',
              backgroundColor: isSelected ? '#21222E' : '#fff',
            },
          }}
        >
          {attr}
        </Button>
      );
    });
  }

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Typography fontSize='24px' lineHeight='30px' color='#21222E' mb='34px'>
        {lang.attributesTitleFirstPart}
        <span style={{ fontWeight: 700 }}>{lang.like}</span>
        {lang.attributesTitleSecondPart}
      </Typography>
      <Box display='flex' flexWrap='wrap' mb='30px' justifyContent='center'>
        {renderAttr()}
      </Box>
      <LoadingButton
        onClick={() => onClick(selectedAttr)}
        loading={loading}
        variant='contained'
        sx={{
          width: '100%',
          textTransform: 'none',
          backgroundColor: '#21222E',
          borderRadius: '8px',
          fontWeight: 500,
          fontSize: '16px',
          '&:hover': {
            backgroundColor: '#21222E',
          },
        }}
      >
        {lang.leaveYourReview}
      </LoadingButton>
    </Box>
  );
}
