import '../css/social.css';
import { useAppSelector } from '../store/store';
import { redirectOnPlatform } from './../api/api';
import { PlatformInfo } from '../api/api_types';
import Platforms from './components/platforms';

function Social() {
  const info = useAppSelector((state) => state.system.info);

  console.log('social');

  function onRedirect(platform: PlatformInfo) {
    const inviteId = info?.id;
    if (inviteId) {
      const platformId = platform.rankPlatformId;
      redirectOnPlatform(inviteId, platformId);
    }
  }

  return (
    <Platforms
      rankPlatforms={info?.rankPlatforms}
      onRedirect={(platform: PlatformInfo) => onRedirect(platform)}
    />
  );
}

export default Social;
