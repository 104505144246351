import '../../css/footer.css';

function Footer() {
  return (
    <footer className='footer'>
      <a href='https://askreview.net/' className='footer__link'>
        <p className='footer_text'>Powered by</p>
        <img
          className='footer__logo'
          src='/images/logo_horizontal.svg'
          alt='Ask logo'
        />
      </a>
    </footer>
  );
}

export default Footer;
