import { createBrowserRouter } from 'react-router-dom';
import Error from '../js/error';
import AuditPage from '../js/audit/audit_page';

export const auditRouter = createBrowserRouter([
  {
    path: '/audit/:id',
    errorElement: <Error />,
    element: <AuditPage />,
  },
]);
