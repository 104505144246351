import { createSlice } from "@reduxjs/toolkit";
import * as ua from "../js/lang/ua.json";
import * as en from "../js/lang/en.json";
import * as pl from "../js/lang/pl.json";
import * as de from "../js/lang/de.json";

export interface LangState {
    lang: typeof ua | typeof en | typeof pl | typeof de,
}

export const langSlice = createSlice({
    name: "lang",
    initialState: {
        lang: ua
    },
    reducers: {
        setLang: (state, action) => {
            state.lang = action.payload;
        },
    }
});

export const { setLang } = langSlice.actions;

export default langSlice.reducer;
