import { configureStore } from '@reduxjs/toolkit';
import langReducer, { LangState } from './lang';
import systemReducer, { SystemState } from './system';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import qrReducer, { QrState } from './qr';

export interface RootState {
  system: SystemState;
  lang: LangState;
  qr: QrState;
}

export const store = configureStore({
  reducer: { lang: langReducer, system: systemReducer, qr: qrReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
