import { Button } from '@mui/material';
import { motion } from 'framer-motion';
import DefaultBtn from '../modules/default_btn';
import { useAppSelector } from '../../store/store';
import { PlatformInfo, RankPlatformName } from '../../api/api_types';

interface MainPlatformProps {
  id?: string;
  mainRankPlatformType?: RankPlatformName;
  rankPlatforms?: PlatformInfo[];
  onOtherClick: () => void;
  onRedirect: () => void;
}

export default function MainPlatform(props: MainPlatformProps) {
  const lang = useAppSelector((state) => state.lang);
  const { onOtherClick, mainRankPlatformType, rankPlatforms, onRedirect } = props;
  const mainPlatform = rankPlatforms?.find(e => e.rankPlatformType === mainRankPlatformType);

  function getTitle() {
    var text = lang.lang.leaveReview;

    return (
      <div>
        {text}
        {<span className='platform__span'>{getPlatformName()}</span>}
        {'?'}
      </div>
    );
  }

  function getPlatformName() {
    var name: string;
    switch (mainRankPlatformType) {
      case RankPlatformName.Google:
        name = 'Google';
        break;
      case RankPlatformName.Trustpilot:
        name = 'Trustpilot';
        break;
      case RankPlatformName.Yelp:
        name = 'Yelp';
        break;
      case RankPlatformName.Tripadvisor:
        name = 'Tripadvisor';
        break;
      case RankPlatformName.Facebook:
      case RankPlatformName.FacebookPost:
        name = 'Facebook';
        break;
      case RankPlatformName.InstagramPost:
        name = 'Instagram';
        break;
      case RankPlatformName.Hotline:
        name = 'Hotline';
        break;
      case RankPlatformName.Custom:
        name = mainPlatform?.name ?? '';
        break;
      default:
        name = '';
        break;

    }
    return name;
  }

  return (
    <motion.div
      className='platform'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className='platform__title'>{getTitle()}</h2>
      <DefaultBtn text={lang.lang.yes} onClick={onRedirect} backgroundColor={mainPlatform?.color}/>
      <Button
        className='platform__btn'
        onClick={onOtherClick}
        sx={{
          marginTop: '10px',
          border: '1px solid #D2D2D2',
          textTransform: 'initial',
          borderRadius: '8px',
          width: '100%',
          fontSize: '20px',
          fontWeight: 400,
          lineHeight: '28px',
          color: '#929292',
          borderColor: '#929292',
          padding: '12px 0',
        }}
        variant='outlined'
      >
        {lang.lang.useOtherSite}
      </Button>
    </motion.div>
  );
}
