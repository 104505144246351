import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

type DefaultBtnProps = {
  text: string;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  loading?: boolean;
  iconPath?: string;
  subText?: string;
  onClick: () => void;
};

function DefaultBtn({
  text,
  color = '#fff',
  disabled = false,
  loading = false,
  iconPath,
  backgroundColor = '#21222E',
  subText,
  onClick,
}: DefaultBtnProps) {
  function getHoverColor() {
    const alpha = 0.7;

    const r = parseInt(backgroundColor.slice(1, 3), 16);
    const g = parseInt(backgroundColor.slice(3, 5), 16);
    const b = parseInt(backgroundColor.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return (
    <LoadingButton
      onClick={onClick}
      variant='contained'
      disabled={disabled}
      loading={loading}
      sx={{
        pl: 2,
        margin: '10px 0',
        textTransform: 'initial',
        backgroundColor: backgroundColor,
        borderRadius: '8px',
        color: color,
        fontSize: '20px',
        lineHeight: '28px',
        padding: '16px 0',
        width: '100%',
        fontWeight: '700',
        ':hover': {
          backgroundColor: getHoverColor(),
        },
      }}
    >
      {text}{' '}
      <span style={{ fontWeight: 400, marginLeft: '5px' }}> {subText}</span>
    </LoadingButton>
  );
}

export default DefaultBtn;
