import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getInfo, sendRate, sendFeedback, selectAttributes } from '../api/api';
import { OrderInfo, SelectAttributesRequest } from '../api/api_types';

export interface SystemState {
  googleAuth: boolean;
  loading: boolean;
  hash: string;
  failure: boolean;
  info: OrderInfo | null | undefined;
}

interface RateRequest {
  id: string;
  isPositive?: boolean;
  rate?: number;
}

interface FeedbackRequest {
  id: string;
  text: string;
}

export const infoThunk = createAsyncThunk(
  'system/info',
  async (hash: string): Promise<OrderInfo | undefined> => {
    const response = await getInfo(hash);
    return response;
  },
);

export const rateThunk = createAsyncThunk(
  'system/rate',
  async ({ id, isPositive, rate }: RateRequest, thunkAPI) => {
    try {
      const response = await sendRate(id, isPositive, rate);
      return { data: response, isPositive: isPositive };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const feedbackThunk = createAsyncThunk(
  'system/feedback',
  async ({ id, text }: FeedbackRequest, thunkAPI) => {
    try {
      const response = await sendFeedback(id, text);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const attributesThunk = createAsyncThunk(
  'system/attributes',
  async (body: SelectAttributesRequest): Promise<boolean> => {
    return await selectAttributes(body);
  },
);

const initState: SystemState = {
  googleAuth: false,
  loading: false,
  hash: '',
  failure: false,
  info: null,
};

export const systemSlice = createSlice({
  name: 'system',
  initialState: initState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFailure: (state, action) => {
      state.failure = action.payload;
    },
    setGoogleAuth: (state, action) => {
      state.googleAuth = action.payload;
    },
    setSaveFailure: (state, action) => {
      state.failure = action.payload;
    },
    setHash: (state, action) => {
      state.hash = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(infoThunk.fulfilled, (state, action) => {
      state.info = action.payload;
    });
    builder.addCase(infoThunk.rejected, (state) => {
      state.failure = true;
    });
    builder.addCase(infoThunk.pending, (state) => {
      state.failure = false;
    });
    builder.addCase(rateThunk.rejected, (state) => {
      state.failure = true;
    });
    builder.addCase(rateThunk.fulfilled, (state, action) => {
      if (!action.payload.isPositive) {
        return;
      }
      if (state.info) {
        state.info.mainRankPlatformType =
          action.payload.data.mainRankPlatformType;
        state.info.redirectOnRate = action.payload.data.redirectOnRate;
        state.info.rankPlatforms = action.payload.data.rankPlatforms;
      } else {
        state.failure = true;
      }
    });
    builder.addCase(rateThunk.pending, (state) => {
      state.failure = false;
    });
    builder.addCase(feedbackThunk.rejected, (state) => {
      state.failure = true;
    });
    builder.addCase(feedbackThunk.pending, (state) => {
      state.failure = false;
    });
    builder.addCase(attributesThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(attributesThunk.rejected, (state) => {
      state = { ...state, loading: false, failure: true };
    });
    builder.addCase(attributesThunk.fulfilled, (state) => {
      state.failure = false;
      state.loading = false;
    });
  },
});

export const {
  setLoading,
  setSaveFailure,
  setHash,
  setGoogleAuth,
  setFailure,
} = systemSlice.actions;
export default systemSlice.reducer;
