import React from 'react';
import { useAppSelector } from '../store/store';
import '../css/success.css';
import { motion } from 'framer-motion';

function Success() {
  const lang = useAppSelector((state) => state.lang);

  return (
    <motion.div
      className='success'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <img className='success__img' src='/images/success.svg' alt='success' />
      <h2 className='success__title'>{lang.lang.thankYou}</h2>
      <p className='success__text'>{lang.lang.feedbackSubmitted}</p>
    </motion.div>
  );
}

export default Success;
