import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createQrSession } from '../../api/api';
import { useAppDispatch } from '../../store/store';
import { setFailure } from '../../store/system';

export default function SessionCreator() {
  const { hash } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!hash) {
      dispatch(setFailure(true));
      return;
    }

    const init = async () => {
      const response = await createQrSession(hash);

      if (response) {
        if (typeof response === 'string') {
          navigate(`/qr/s/${response}`);
        } else if (typeof response === 'object' && 'Code' in response) {
          window.location.href = `https://my.askreview.net/new-qr/${hash}`;
        }
      } else {
        dispatch(setFailure(true));
      }
    };

    init();
  }, [dispatch, hash, navigate]);

  return (
    <div>
      <CircularProgress size={50} />
    </div>
  );
}
