import React, { useEffect, useState } from "react";
import "./../css/snack_notif.css";
import { useAppSelector } from "../store/store";

function SnackNotif() {
    const [showSnackbar, setShowSnackbar] = useState(true);
    const failure = useAppSelector(state => state.system.failure);
    const lang = useAppSelector(state => state.lang);

    const isVisible = () => failure && showSnackbar;

    useEffect(() => {
        if (failure) {
            setShowSnackbar(true);

            const timer = setTimeout(() => {
                setShowSnackbar(false);
            }, 2000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [failure]);

    return (
        <div className="snackbar" style={{ opacity: isVisible() ? 1 : 0 }}>{lang.lang.failure}</div>
    );
}

export default SnackNotif;