import { useState } from 'react';
import '../css/feedback.css';
import { feedbackThunk, setSaveFailure } from '../store/system';
import { useAppDispatch, useAppSelector } from '../store/store';
import { useNavigate } from 'react-router-dom';
import FeedbackPage from './components/feedback';

function Feedback() {
  console.log('feedback');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const state = useAppSelector((state) => state.system);
  const dispatch = useAppDispatch();

  const [text, setText] = useState('');

  const onChange = (event: { target: { value: string } }) => {
    setText(event.target.value);
  };

  const isBtnDisabled = () => text.length === 0;

  const onClick = () => {
    if (state.info !== null) {
      setLoading(true);
      dispatch(feedbackThunk({ id: state.info!.id, text: text }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            navigate('/success');
          }
        })
        .catch((_) => {
          dispatch(setSaveFailure(true));
        });
      setLoading(false);
    }
  };

  return (
    <FeedbackPage
      loading={loading}
      title={state.info?.negativeTitle}
      question={state.info?.negativeQuestion}
      onClick={onClick}
      onChange={onChange}
      text={text}
      disabled={isBtnDisabled()}
    />
  );
}

export default Feedback;
