import AttributesSelector from './components/attributes_selector';
import { useAppDispatch, useAppSelector } from '../store/store';
import { attributesThunk } from '../store/system';
import { useNavigate } from 'react-router-dom';
import { RankPlatformName } from '../api/api_types';
import { redirectOnPlatform } from '../api/api';

export default function Attributes() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.system);

  const onClick = (attributes: string[]) => {
    if (state.info) {
      const body = { attributes: attributes, reviewInviteId: state.info?.id };
      dispatch(attributesThunk(body)).then((res) => {
        if (typeof res !== 'boolean' && res) {
          onSuccess();
        }
      });
    }
  };

  function onSuccess() {
    if (state.info?.mainRankPlatformType && state.info.redirectOnRate) {
      redirect();
    } else if (state.info?.mainRankPlatformType) {
      navigate('/platform');
    } else {
      navigate('/social');
    }
  }

  function redirect() {
    if (
      state.info?.mainRankPlatformType === RankPlatformName.Google &&
      !state.googleAuth
    ) {
      navigate('/platform');
    } else {
      redirectOnPlatform(state.info!.id, getPlatformId());
    }
  }

  function getPlatformId() {
    const platforms = state.info?.rankPlatforms;
    const redirectPlatform = platforms?.find(
      (p) => p.rankPlatformType === state.info?.mainRankPlatformType,
    );
    return redirectPlatform?.rankPlatformId ?? '';
  }

  return (
    <AttributesSelector
      attributes={state.info?.attributes ?? []}
      onClick={onClick}
      loading={state.loading}
    />
  );
}
