import { Box } from '@mui/material';

export default function Marker(props: {
  lat: number;
  lng: number;
  hide: boolean;
  value?: number;
  color?: string;
}) {
  const value = () => {
    return props.hide ? '?' : props?.value ?? '21+';
  };

  function getColor(value?: number) {
    if (props.hide) {
      return '#3780FF';
    } else if (!value) {
      return '#840000';
    } else if (value < 4) {
      return '#38B137';
    } else if (value < 7) {
      return '#8AB137';
    } else if (value < 10) {
      return '#B1AC37';
    } else if (value < 13) {
      return '#B18037';
    } else if (value < 16) {
      return '#B16337';
    } else if (value < 19) {
      return '#B14D37';
    } else if (value < 20) {
      return '#B62727';
    } else {
      return '#840000';
    }
  }

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      sx={{
        backgroundColor: getColor(props.value),
        borderRadius: 50,
        height: '24px',
        width: '24px',
        color: '#fff',
      }}
    >
      {value()}
    </Box>
  );
}
