import Axios, { AxiosError } from 'axios';
import {
  ApiFailure,
  AuditInfo,
  OrderInfo,
  QrNegativeFeedbackRequest,
  QrPositiveStepResponse,
  QrRateRequest,
  QrRateStepResponse,
  RateResponse,
  SelectAttributesRequest,
  SessionInfo,
} from './api_types';

export async function getInfo(hash: string): Promise<OrderInfo | undefined> {
  try {
    const response = await Axios.get(
      process.env.REACT_APP_SERVER_URL + 'widget/inviteInfo/' + hash,
    );
    const orderInfo = response.data;

    return orderInfo;
  } catch (error) { }
}

export async function sendRate(
  id: string,
  isPositive?: boolean,
  rate?: number,
): Promise<RateResponse> {
  const body = JSON.stringify({
    reviewInviteId: id,
    isPositive: isPositive,
    rate: rate,
  });
  const result = await Axios.post(
    process.env.REACT_APP_SERVER_URL + 'widget/rate',
    body,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return result.data;
}

export async function sendFeedback(id: string, feedback: string) {
  const body = JSON.stringify({
    reviewInviteId: id,
    feedback: feedback,
  });
  const result = await Axios.post(
    process.env.REACT_APP_SERVER_URL + 'widget/addNegativeFeedback',
    body,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  return result;
}

export function qrRedirectOnPlatform(
  qrCode: string | undefined,
  platformId: string,
) {
  try {
    const url = `${process.env.REACT_APP_SERVER_URL}QrWidget/invite/${qrCode}/link/${platformId}`;

    window.location.href = url;
  } catch (error) { }
}

export function redirectOnPlatform(
  inviteId: string | undefined,
  platformId: string,
) {
  try {
    const url = `${process.env.REACT_APP_SERVER_URL}widget/invite/${inviteId}/link/${platformId}`;

    window.location.href = url;
  } catch (error) { }
}

export async function qrRateStep(
  hash: string,
): Promise<QrRateStepResponse | undefined | number> {
  try {
    const response = await Axios.get(
      process.env.REACT_APP_SERVER_URL + `QrWidget/rateStep/${hash}`,
    );

    return response.data;
  } catch (error) {
    if (Axios.isAxiosError(error) && error.response) {
      const statusCode = error.response.data.Code;
      return statusCode;
    }
  }
}

export async function qrRate(body: QrRateRequest) {
  try {
    const json = JSON.stringify(body);
    await Axios.post(process.env.REACT_APP_SERVER_URL + `QrWidget/rate`, json, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) { }
}

export async function qrNegativeStep(
  hash: string,
): Promise<QrRateStepResponse | undefined> {
  try {
    const response = await Axios.get(
      process.env.REACT_APP_SERVER_URL + `QrWidget/negativeStep/${hash}`,
    );
    return response.data;
  } catch (error) { }
}

export async function qrNegativeFeedback(
  body: QrNegativeFeedbackRequest,
): Promise<boolean> {
  try {
    const json = JSON.stringify(body);
    await Axios.post(
      process.env.REACT_APP_SERVER_URL + `QrWidget/addNegativeFeedback`,
      json,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return true;
  } catch (error) {
    return false;
  }
}

export async function qrPositiveStep(
  hash: string,
): Promise<QrPositiveStepResponse | undefined> {
  try {
    const response = await Axios.get(
      process.env.REACT_APP_SERVER_URL + `QrWidget/positiveStep/${hash}`,
    );
    return response.data;
  } catch (error) { }
}

export async function selectAttributes(
  request: SelectAttributesRequest,
): Promise<boolean> {
  try {
    const json = JSON.stringify(request);
    await Axios.post(
      process.env.REACT_APP_SERVER_URL + `widget/selectAttributes`,
      json,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return true;
  } catch (error) {
    return false;
  }
}

export async function createQrSession(
  hash: string,
): Promise<string | ApiFailure | undefined> {
  try {
    const response = await Axios.post(
      process.env.REACT_APP_SERVER_URL + `QrWidget/createSession/${hash}`,
    );

    return response.data.qrCodeSessionId;
  } catch (e) {
    const error = e as AxiosError;
    const info = error.response?.data as ApiFailure;
    return info;
  }
}

export async function getSessionInfo(
  sessionId: string,
): Promise<SessionInfo | undefined> {
  try {
    const response = await Axios.get(
      process.env.REACT_APP_SERVER_URL + `QrWidget/session/${sessionId}`,
    );

    return response.data;
  } catch (e) { }
}

export async function selectQrAttributes(
  request: SelectAttributesRequest,
): Promise<boolean> {
  try {
    const json = JSON.stringify(request);
    await Axios.post(
      process.env.REACT_APP_SERVER_URL + `QrWidget/selectAttributes`,
      json,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return true;
  } catch (error) {
    return false;
  }
}

export async function getAuditInfo(id: string): Promise<AuditInfo | undefined> {
  try {
    const response = await Axios.get(
      process.env.REACT_APP_SERVER_URL + `client/audit/${id}`,
    );

    return response.data;
  } catch (e) { }
}
