import '../css/main.css';
import MainPage from './main_page';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Social from './social';
import Feedback from './feedback';
import '../css/main.css';
import Error from './error';
import { useEffect } from 'react';
import * as en from './lang/en.json';
import * as pl from './lang/pl.json';
import * as ua from './lang/ua.json';
import * as de from './lang/de.json';
import { infoThunk, setHash, setLoading } from '../store/system';
import { setLang } from '../store/lang';
import { useAppDispatch, useAppSelector } from '../store/store';
import Footer from './modules/footer';
import Success from './success';
import SnackNotif from './snack_notif';
import './../css/general.css';
import { AnimatePresence } from 'framer-motion';
import PlatformReview from './platform_review';
import { OrderInfo, RankPlatformName, Status } from '../api/api_types';
import { redirectOnPlatform } from '../api/api';
import Attributes from './attributes';

function App() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.system);

  useEffect(() => {
    dispatch(setLoading(true));
    const link = window.location.pathname.replace('/', '');
    dispatch(setHash(link));

    const checkGoogleAuth = async () => {
      return await new Promise((res, rej) => {
        const img = new Image();
        img.src =
          'https://accounts.google.com/CheckCookie?continue=https%3A%2F%2Fwww.google.com%2Fintl%2Fen%2Fimages%2Flogos%2Faccounts_logo.png&followup=https%3A%2F%2Fwww.google.com%2Fintl%2Fen%2Fimages%2Flogos%2Faccounts_logo.png&chtml=LoginDoneHtml&checkedDomains=youtube&checkConnection=youtube%3A291%3A1';

        img.onload = () => {
          res(true);
        };

        img.onerror = () => {
          rej(false);
        };
      });
    };

    function _getPlatformId(info: OrderInfo) {
      const platforms = info.rankPlatforms;
      if (platforms) {
        const redirectPlatform = platforms.find(
          (p) => p.rankPlatformType === info.mainRankPlatformType,
        );
        return redirectPlatform?.rankPlatformId ?? '';
      }
      return '';
    }

    async function redirect(info: OrderInfo) {
      if (info.redirectOnRate) {
        const googleAuth = await checkGoogleAuth();
        if (
          info.mainRankPlatformType !== RankPlatformName.Google ||
          googleAuth
        ) {
          redirectOnPlatform(info.id, _getPlatformId(info));
          return;
        } else if (info.mainRankPlatformType === null) {
          navigate('/social');
        } else {
          navigate('/platform');
        }
      }
    }

    dispatch(infoThunk(link)).then((res) => {
      if (
        res.meta.requestStatus === undefined ||
        res.meta.requestStatus === 'rejected' ||
        res.payload === undefined
      ) {
        navigate('/error');
        dispatch(setLoading(false));
        return;
      }
      var orderInfo = res.payload as OrderInfo;
      switchLang(orderInfo.language);

      if (orderInfo.status === Status.NegativeRate) {
        orderInfo.hasFeedback ? navigate('/success') : navigate('/feedback');
      } else if (orderInfo.status === Status.PositiveRate) {
        navigate('/social');
      } else if (!orderInfo.negativeFilterEnabled) {
        redirect(orderInfo);
      } else {
        navigate('/main');
      }
      dispatch(setLoading(false));
    });

    function switchLang(language: string) {
      switch (language) {
        case 'En':
          dispatch(setLang(en));
          break;
        case 'Pl':
          dispatch(setLang(pl));
          break;
        case 'De':
          dispatch(setLang(de));
          break;
        default:
          dispatch(setLang(ua));
          break;
      }
    }
  }, []);

  return (
    <div>
      {state.loading && (
        <div className='loader__wrapper'>
          <div className='loader'></div>
        </div>
      )}
      <div className='container'>
        <div className='content__wrapper'>
          <div className='routes__box'>
            <RoutesAnimation />
          </div>
          <SnackNotif />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;

function RoutesAnimation() {
  const location = useLocation();

  return (
    <AnimatePresence mode='wait'>
      <Routes location={location} key={location.pathname}>
        <Route path='/' element={<div />} />
        <Route path='/feedback' element={<Feedback key='feedback' />} />
        <Route path='/social' element={<Social key='social' />} />
        <Route path='/platform' element={<PlatformReview key='platform' />} />
        <Route path='/main' element={<MainPage key='main' />} />
        <Route path='/error' element={<Error key='error' />} />
        <Route path='/success' element={<Success key='success' />} />
        <Route path='/attributes' element={<Attributes key='attributes' />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </AnimatePresence>
  );
}
