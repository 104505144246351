import { motion } from 'framer-motion';
import { useAppSelector } from '../../store/store';
import DefaultBtn from '../modules/default_btn';
import { PlatformInfo, RankPlatformName } from '../../api/api_types';

interface PlatformsProps {
  rankPlatforms?: PlatformInfo[];
  onRedirect: (platform: PlatformInfo) => void;
}
export default function Platforms(props: PlatformsProps) {
  const { rankPlatforms, onRedirect } = props;
  const lang = useAppSelector((state) => state.lang);

  function renderLinks() {
    if (rankPlatforms !== undefined) {
      return rankPlatforms.map((platform: PlatformInfo) => {
        const text = getPlatformName(platform);
        return (
          <DefaultBtn
            key={platform.rankPlatformType}
            backgroundColor={getColor(platform)}
            text={text}
            subText={getSubText(platform.rankPlatformType)}
            onClick={() => onRedirect(platform)}
          />
        );
      });
    }
  }

  function getSubText(platformName: RankPlatformName) {
    var subText = '';
    if (!rankPlatforms) {
      return subText;
    }
    const platforms = rankPlatforms;
    const facebookExiste = platforms.some(
      (p) => p.rankPlatformType === RankPlatformName.Facebook,
    );
    const facebookPostExiste = platforms.some(
      (p) => p.rankPlatformType === RankPlatformName.FacebookPost,
    );

    if (facebookExiste && facebookPostExiste) {
      if (platformName === RankPlatformName.Facebook) {
        subText = lang.lang.review;
      } else if (platformName === RankPlatformName.FacebookPost) {
        subText = lang.lang.comment;
      }
    }

    return subText;
  }

  function getColor(platform: PlatformInfo) {
    if (platform.color) {
      return platform.color;
    }
    var color: string | null;
    switch (platform.rankPlatformType) {
      case RankPlatformName.Google:
        color = '#57A6F6';
        break;
      case RankPlatformName.Trustpilot:
        color = '#04DA8D';
        break;
      case RankPlatformName.Yelp:
        color = '#E00707';
        break;
      case RankPlatformName.Tripadvisor:
        color = '#34e0a1';
        break;
      case RankPlatformName.Facebook:
      case RankPlatformName.FacebookPost:
        color = '#5D80BD';
        break;
      case RankPlatformName.InstagramPost:
        color = '#E1306C';
        break;
      case RankPlatformName.Hotline:
        color = '#555';
        break;
      case RankPlatformName.Custom:
        color = '#000';
    }
    return color;
  }

  function getPlatformName(platform: PlatformInfo) {
    var name: string;
    switch (platform.rankPlatformType) {
      case RankPlatformName.Google:
        name = 'Google';
        break;
      case RankPlatformName.Trustpilot:
        name = 'Trustpilot';
        break;
      case RankPlatformName.Yelp:
        name = 'Yelp';
        break;
      case RankPlatformName.Tripadvisor:
        name = 'Tripadvisor';
        break;
      case RankPlatformName.Facebook:
      case RankPlatformName.FacebookPost:
        name = `Facebook`;
        break;
      case RankPlatformName.InstagramPost:
        name = 'Instagram';
        break;
      case RankPlatformName.Hotline:
        name = 'Hotline';
        break;
      case RankPlatformName.Custom:
        name = platform.name;
        break;
    }
    return name;
  }

  return (
    <motion.div
      className='social'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className='social__title'>{lang.lang.socialTitle}</h2>
      {renderLinks()}
    </motion.div>
  );
}
