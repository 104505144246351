import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/reset.css';
import './css/fonts.css';
import './index.css';
import RouterManager from './router/router_manager';
import { Provider } from 'react-redux';

import reportWebVitals from './reportWebVitals';
import { store } from './store/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <RouterManager />
  </Provider>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
