import { Outlet, createBrowserRouter } from 'react-router-dom';
import Footer from '../js/modules/footer';
import Error from '../js/error';
import ErrorAlert from '../js/qr/alert';
import SessionCreator from '../js/qr/session_creator';
import SessionProtect from '../js/qr/session_protect';
import QRFlowView from '../js/qr/qr_flow_view';

export const router = createBrowserRouter([
  {
    path: '/qr',
    errorElement: <Error />,
    element: (
      <div
        style={{ height: '100svh', display: 'flex', flexDirection: 'column' }}
      >
        <div className='container'>
          <Outlet />
        </div>
        <Footer />
        <ErrorAlert />
      </div>
    ),
    children: [
      {
        path: ':hash',
        element: <SessionCreator />,
      },
      {
        path: '',
        element: <SessionProtect />,
        children: [{ path: 's/:sessionId', element: <QRFlowView /> }],
      },
    ],
  },
]);
