import './style.css';

export default function Title(props: { text: string }) {
  return (
    <h2
      style={{
        marginTop: '30px',
        marginBottom: '10px',
        fontSize: '20px',
        fontWeight: 500,
        color: '#3780FF',
      }}
    >
      {props.text}
    </h2>
  );
}
