import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  QrRateRequest,
  SelectAttributesRequest,
  SessionInfo,
} from '../api/api_types';
import { getSessionInfo, qrRate, selectQrAttributes } from '../api/api';
import { RootState } from './store';

export interface QrState {
  sessionId: string;
  sessionInfo?: SessionInfo;
  loading: boolean;
}

interface RateRequest {
  isPositiveRate?: boolean;
  onReject: Function;
  rate?: number;
}

interface SelectAttributesBody {
  attributes: string[];
  onReject: Function;
}

const initState: QrState = {
  sessionId: '',
  loading: false,
};

export const sessionInfoThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>(
  'qr/session',
  async (onReject: Function, thunkAPI): Promise<SessionInfo | undefined> => {
    try {
      const state = thunkAPI.getState();
      return await getSessionInfo(state.qr.sessionId);
    } catch (e) {
      onReject();
    }
  },
);

export const rateThunk = createAsyncThunk<any, any, { state: RootState }>(
  'qr/rate',
  async ({ isPositiveRate, onReject, rate }: RateRequest, thunkAPI) => {
    const qrState = thunkAPI.getState().qr;
    const body: QrRateRequest = {
      qrCodeSessionId: qrState.sessionInfo?.qrCodeSessionId ?? '',
      isPositive: isPositiveRate,
      rate: rate,
    };
    try {
      await qrRate(body);
    } catch (e) {
      onReject();
    }
  },
);

export const selectAttributesThunk = createAsyncThunk<
  any,
  any,
  { state: RootState }
>(
  'qr/selectAttributes',
  async ({ attributes, onReject }: SelectAttributesBody, thunkAPI) => {
    const sessionId = thunkAPI.getState().qr.sessionId;
    const body: SelectAttributesRequest = {
      reviewInviteId: sessionId,
      attributes: attributes,
    };
    try {
      await selectQrAttributes(body);
    } catch (e) {
      onReject();
    }
  },
);

export const qrSlice = createSlice({
  name: 'qr',
  initialState: initState,
  reducers: {
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sessionInfoThunk.pending, (state) => {
      state = { ...state, loading: true };
    });
    builder.addCase(sessionInfoThunk.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        sessionInfo: action.payload,
      };
    });
    builder.addCase(sessionInfoThunk.rejected, (state) => {
      state = { ...state, loading: false };
    });
    builder.addCase(rateThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(rateThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(selectAttributesThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(selectAttributesThunk.pending, (state) => {
      state.loading = true;
    });
  },
});

export const { setSessionId, setLoading } = qrSlice.actions;
export default qrSlice.reducer;
