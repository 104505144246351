import AttributesSelector from '../components/attributes_selector';

interface AttributesViewProps {
  onClick: Function;
  attributes: string[];
  loading: boolean;
}

export default function AttributesView(props: AttributesViewProps) {
  const { onClick, attributes, loading } = props;

  return (
    <AttributesSelector
      onClick={(attributes) => onClick(attributes)}
      attributes={attributes ?? []}
      loading={loading}
    />
  );
}
