import { useAppSelector } from '../store/store';
import './../css/platform.css';
import { useNavigate } from 'react-router-dom';
import MainPlatform from './components/main_platform';
import { redirectOnPlatform } from '../api/api';

function PlatformReview() {
  const state = useAppSelector((state) => state.system);
  const navigate = useNavigate();

  const onOtherClick = () => navigate('/social');

  const onRedirect = () => redirectOnPlatform(state.info?.id, getPlatformId());

  function getPlatformId() {
    const platforms = state.info?.rankPlatforms;
    if (platforms) {
      const redirectPlatform = platforms.find(
        (p) => p.rankPlatformType === state.info?.mainRankPlatformType,
      );
      return redirectPlatform?.rankPlatformId ?? '';
    }
    return '';
  }

  return (
    <MainPlatform
      id={state.info?.id}
      mainRankPlatformType={state.info?.mainRankPlatformType}
      rankPlatforms={state.info?.rankPlatforms}
      onOtherClick={onOtherClick}
      onRedirect={onRedirect}
    />
  );
}

export default PlatformReview;
