import React from 'react';
import '../css/error.css';
import { useAppSelector } from '../store/store';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

function Error() {
  const lang = useAppSelector((state) => state.lang.lang);

  return (
    <motion.div
      className='error'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className='error__title'>{lang.error}</h2>
      <div className='textError'>404</div>
    </motion.div>
  );
}

export default Error;
